import acpEnvChooserTemplate from './templates/acp-env-chooser.html';
// @ngInject
function acpEnvChooser() {
  return {
    restrict: 'E',
    controller: 'AcpEnvChooserCtrl',
    template: acpEnvChooserTemplate,
    scope: {}
  };
}

export default acpEnvChooser;
