import envChooserContent from './env-chooser-content.yml';
import acpEnvChooserDirective from './acp-env-chooser-directive';
import acpEnvChooserCtrl from './acp-env-chooser-ctrl';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

var componentName = 'acp.component.environment-chooser';

var component = ng
  .module(componentName, [acpCore.name])
  .controller('AcpEnvChooserCtrl', acpEnvChooserCtrl)
  .directive('acpEnvChooser', acpEnvChooserDirective)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put(
        'components/environment-chooser',
        envChooserContent
      );
    }
  );

export default component;
