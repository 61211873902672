// @ngInject
function AcpEnvironmentChooserCtrl($log, $scope, $window) {
  $scope.currentEnvironment = null;
  $scope.currentEnvironment = $window.location.href;

  $scope.submit = function(params) {
    $log.debug('AcpEnvironmentChooserCtrl.submit()', params);
    $window.location.assign(params.specifiedEnv);
  };
}
export default AcpEnvironmentChooserCtrl;
